import { useState } from "react";

function CountDown({ h, m, s }) {
  const [hours, setHour] = useState(h);
  const [menotes, setMenotes] = useState(m);
  const [seconds, setSeconds] = useState(s);

  const timer = setTimeout(() => {
    setSeconds(seconds - 1);
    if (seconds === 0) {
      setMenotes(menotes - 1);
      setSeconds(59);
    }
    if (menotes === 0 && seconds === 0) {
      setHour(hours - 1);
      setMenotes(59);
    }
  }, 1000);

  if (hours === 0 && menotes === 0 && seconds === 0) {
    clearTimeout(timer);
  }

  return (
    <div className="d-flex justify-content-start align-items-center text-white">
      <h6 className="box-count-down">{hours < 10 ? "0" + hours : hours}</h6>
      <h6 style={{ marginRight: "10px", fontWeight: "800" }}>:</h6>
      <h6 className="box-count-down">
        {menotes < 10 ? "0" + menotes : menotes}
      </h6>
      <h6 style={{ marginRight: "10px", fontWeight: "800" }}>:</h6>
      <h6 className="box-count-down">
        {seconds < 10 ? "0" + seconds : seconds}
      </h6>
    </div>
  );
}

export default CountDown;
